<style scoped lang="scss">
  .role_list_box {
    .addbtn {
      margin-bottom: 10px;
    }
    /deep/ .page-roles-el-dialog {
      max-width: 520px;
    }
  }
</style>

<template>
  <div class="role_list_box">
    <div class="addbtn">
      <el-button type="primary" plain size="small" @click="_edit()" v-if="isRolesCreate"><i class="fa fa-plus"></i> 新增</el-button>
    </div>
    <el-table :data="tableData">
      <el-table-column type="expand" fixed>
        <template slot-scope="props">
          <el-form label-position="left" inline class="c-table-expand">
            <el-form-item label="角色ID">
              <span>{{ props.row.id }}</span>
            </el-form-item>
            <el-form-item label="角色名">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="是否默认角色">
              <span>{{ props.row.isDefault ? "是" : "否" }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="角色名" prop="name"></el-table-column>
      <el-table-column label="是否默认角色">
        <template slot-scope="scope">
          {{ scope.row.isDefault ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button type="primary" plain size="mini" @click.native="_permission(scope.row)" v-if="isRolesPermissions">权限</el-button>
          <el-button type="warning" plain size="mini" @click.native="_edit(scope.row)" v-if="isRolesUpdate">编辑</el-button>
          <el-button type="danger" plain size="mini" @click.native="_delete(scope.row)" v-if="isRolesDelete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
      :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
      layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <el-dialog title="角色编辑" custom-class="c-el-dialog page-roles-el-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
      <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
        <el-form-item label="角色名称" prop="name">
          <el-input placeholder="请输入角色名称" v-model="dialogData.name"></el-input>
        </el-form-item>
        <el-form-item label="默认角色" prop="isDefault">
          <el-radio-group v-model="dialogData.isDefault">
            <el-radio :label="false" border size="small" style="margin-right: 5px;">否</el-radio>
            <el-radio :label="true" border size="small">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="_close()">取消</el-button>
        <el-button size="small" type="primary" plain @click="_save()">保存</el-button>
      </span>
    </el-dialog>

    <!--设置角色-->
    <role-edit :ref="refRoleEdit" @refreshData="getListData()"></role-edit>
  </div>
</template>

<script>
  import configMixins from "@/mixins/config"
  import permission from "@/mixins/permission"
  import * as roles from "@/api/system/role"
  import RoleEdit from "@/views/admin/permission/role"
  export default {
    name: "role",
    mixins: [configMixins, permission],
    components: { RoleEdit },
    data() {
      return {
        refRoleEdit: "refRoleToPermissionRole",
        tableData: [],
        total: null,

        listQuery: {
          keywords: null,
          page: 1,
          size: window.$common.appPageSize
        },
        isPagination: false,
        showDialog: false,
        dialogType: "",
        dialogData: {},
        formRefName: 'formRefName',
        formRules: {
          isDefault: [{ required: true, message: '请选择是否默认角色', trigger: 'blur' }]
        },
      }
    },
    mounted() {
      this.getListData()
    },
    methods: {
      handleSizeChange(val) {
        window.$common.fullLoading()
        this.listQuery.size = val
        this.getListData()
      },
      handleCurrentChange(val) {
        window.$common.fullLoading()
        this.listQuery.page = val
        this.getListData()
      },
      getListData() {
        window.$common.fullLoading()
        let params = this.mxPageToSkipCount(this.listQuery)
        roles.getAll(params).then(response => {
          window.$common.closeFullLoading()
          this.total = response.totalCount
          this.isPagination = response.totalCount > 0 || false
          if (response && response.items && response.items.length > 0) {
            this.tableData = response.items
          } else {
            this.tableData = []
          }
        })
      },
      _permission(row) {
        this.$refs[this.refRoleEdit].open(row)
      },
      async _edit(row) {
        this.showDialog = true
        if (row) {
          this.dialogType = this.createOrUpdate[1]
          roles.getDetail({id: row.id}).then(res => {
            this.dialogData = { ...res }
          })
        } else {
          this.dialogType = this.createOrUpdate[0]
          this.dialogData = {
            id: 0,
            name: "",
            isPublic: true,
            isDefault: false
          }
        }
      },
      _save() {
        this.$refs[this.formRefName].validate(valid => {
          if (valid) {
            window.$common.fullLoading()
            let commitTemp = {
              id: this.dialogData.id,
              name: this.dialogData.name,
              isPublic: this.dialogData.isPublic,
              isDefault: this.dialogData.isDefault
            }
            if (this.dialogType === this.createOrUpdate[0]) {
              roles.create(commitTemp).then(() => {
                window.$common.closeFullLoading()
                this.commonSuccess()
              })
            } else if (this.dialogType === this.createOrUpdate[1]) {
              roles.update(commitTemp).then(() => {
                window.$common.closeFullLoading()
                this.commonSuccess()
              })
            }
            
          } else {
            return false
          }
        })
      },
      _delete(row) {
        if (row) {
          this.confirm("确定要删除名为“" + row.name + "”的角色吗？").then(() => {
            window.$common.fullLoading()
            roles.remove({ id: row.id }).then(res => {
              this.commonSuccess()
            })
          })
        }
      },
      _close() {
        this.showDialog = false
        this.$nextTick(() => {
          if (this.$refs[this.formRefName]) {
            this.$refs[this.formRefName].resetFields()
          }
        })
      },
      commonSuccess(val) {
        let tips = val || "操作成功!"
        this.setBroadcast("bdChangeRole", { name: ["user"] })
        this.alert(tips).then(() => {
          this._close()
          window.$common.closeFullLoading()
          this.getListData()
        })
      }
    }
  }
</script>